import { NavLinkMaker } from "./core/core";
import { shopInfo, areaName, hvnUriRoot } from "./core/defines";
import { linkNavs } from "./core/defines";

function navFinder(nameTxt, nav){
    return nav.filter(n=>{
        return n.name == nameTxt
    })[0]
}

const girlRecruit = navFinder("女子求人", linkNavs)
const menRecruit  = navFinder("男子求人", linkNavs)

function Enterance(){
    return(
        <article id="enterence" className="txt-center">
            <header>{ areaName }のソープランド 【{ shopInfo.name }】</header>
            <picture>
                <source media="(max-width: 720px)" srcSet="/img/20240801_entrance-sp.jpg" />
                <source media="(min-width: 721px)" srcSet="/img/20240801_entrance-pc.jpg" />
                <img src="/img/20240801_entrance-sp.jpg" alt="宇都宮のソープランド【チューリップ】" />
            </picture>
            <div className="wrappar">
                <p className="w-100 kin-txt">
                    <img src="/img/18txt.png" width="61" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                    当サイトはアダルトコンテンツを含みます。<br/>
                    18歳未満の方のご利用は固く禁じられています。
                </p>
                <a className="exit" href="https://www.yahoo.co.jp" nofollow>18歳未満の方は<u>コチラ</u>からご退出ください。</a>
                <div className="attention-container">
                    <div className="attention-box">
                        当店は暴力団を含む反社会的団体<br/>
                        及びスカウト等との関わりは一切ございません。
                    </div>
                    <div class="attention-wrap">
                        <div class="title">■加盟協会・団体</div>
                        <ul className="group-box">
                            <li>・全日本特殊浴場協会連合会</li>
                            <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                            <li>・一般社団法人全国防犯健全協力会</li>
                            <li>・東京防犯健全協力会</li>                        
                        </ul>
                    </div>
                </div>
                
                <nav className="enter">
                    <ul>
                        <li><a className="btn to-home" href="/home"><span>ENTER</span>18歳以上のお客様ページ</a></li>
                        <li><a className="btn to-hvn" href={ hvnUriRoot }>ヘブン版ページはコチラ</a></li>
                    </ul>
                    <p style={{textAlign: "center", paddingBottom: "3px", textDecoration: "underline", margin: 0}}>
                        <a href="https://ranking-deli.jp/fuzoku/style5/12/shop/38157/" target="_blank" className="bnr">
                            <img src="https://ranking-deli.jp/assets/img/user/link/bnr30050.jpg" width="300" height="50" border="0" alt="【チューリップ宇都宮店】駅ちか版はコチラ" />
                        </a>
                    </p>

                </nav>
                
                <section className="recruit">
                    <ul className="box">
                        <li><div className="btn women"><NavLinkMaker data={girlRecruit}><span>女性求人</span>ENTER</NavLinkMaker></div></li>
                        <li><a className="btn men"   href={menRecruit.path}><span>男性求人</span>ENTER</a></li>
                    </ul>
                </section>
                <secrion>
                    <div className="bnr-large">
                        <a href="https://marineblue-g.com/" target="_blank" rel="norefarrer noopner">
                            <img src="https://marineblue-g.com/img/bnr-group_20240701.jpg" width="640px" alt="マリングループ" />
                        </a>
                    </div>
                    <div className="bnr-large">
                        <a rel="nofollow" href="https://kitakanto.qzin.jp/tulipg/?v=official" target="_blank"><img src="https://ad.qzin.jp/img/bnr_sp_sample_vanilla.jpg" width="640" border="0" alt="チューリップ宇都宮店で稼ぐならバニラ求人" /></a>
                    </div>
                    <div className="bnr-large">
                        <a href="https://ranking-deli.jp/fuzoku/style5/12/area72/" target="_blank">
                            <img src="https://ranking-deli.jp/assets/img/user/link/2018bnr01.jpg" width="468" height="60" border="0" alt="宇都宮のソープ情報は[駅ちか]におまかせ" />
                        </a>
                    </div>
                    <div className="bnr-large">
                        <a href="https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls/?of=y2"><img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ヘブン版はこちら.png" width="640px" /></a>
                    </div>
                    <div className="bnr-large">
                        <a href="https://www.girlsheaven-job.net/tochigi/ma-209/sa-519/tulipgirls/?of=y"><img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ガールズ版はこちら.png" width="640px" /></a>
                    </div>
                    <div className="bnr-large">                                                 
                        <a href="https://mensheaven.jp/17/tulipgirls/?of=y"><img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ジョブ版はこちら.png" width="640px" /></a>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div className="bnr-wrap">
                                <a style={{display: "block"}} href="https://www.cityheaven.net/" target="_blank" rel="noopener">
                                    <img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/heaven_logo.jpg" alt="ヘブンネット" width="236" height="68" border="0" />
                                </a>
                            </div>
                            <div className="bnr-wrap">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}}  href="https://www.cityheaven.net/tochigi/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/01tochigi.jpg" alt="栃木デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/tochigi/A0901/A090101/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/02utsunomiya.jpg" alt="宇都宮デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/tochigi/A0902/A090203/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/03koyama.jpg" alt="小山デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/gunma/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/04gunma.jpg" alt="群馬デリヘル" width="58" height="34" border="0" /></a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/gunma/A1001/A100102/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/05takasaki.jpg" alt="高崎デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/gunma/A1001/A100101/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/06maebashi.jpg" alt="前橋デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/gunma/A1002/A100202/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/07isesaki.jpg" alt="伊勢崎デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/gunma/A1002/A100203/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/08oota.jpg" alt="太田デリヘル" width="58" height="34" border="0" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div className="bnr-wrap">
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/girls.jpg" alt="ガールズヘブン" width="236" height="68" border="0" /></a>
                            </div>
                            <div className="bnr-wrap">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}}  href="https://www.girlsheaven-job.net/tochigi/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/01tochigi.jpg" alt="栃木風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/tochigi/ma-209/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/02utsunomiya.jpg" alt="宇都宮風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/gunma/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/03gunma.jpg" alt="群馬風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/gunma/ma-155/sa-304/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/04takasaki.jpg" alt="高崎風俗求人" width="58" height="34" border="0" /></a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/gunma/ma-156/sa-385/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/05oota.jpg" alt="太田風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/ibaraki/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/06ibaraki.jpg" alt="茨城風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/07mito.jpg" alt="水戸風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/ibaraki/ma-164/sa-425/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/08tsuchiura.jpg" alt="土浦風俗求人" width="58" height="34" border="0" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div className="bnr-wrap">
                                <a style={{display: "block"}} href="https://mensheaven.jp/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/job.jpg" alt="ジョブヘブン" width="236" height="68" border="0" /></a>
                            </div>
                            <div className="bnr-wrap">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}}  href="https://mensheaven.jp/17/tochigi/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/01tochigi.jpg" alt="栃木スタッフ求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/17/tochigi/ma-209/sa-519/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/02utunomiya.jpg" alt="宇都宮スタッフ求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/17/gunma/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/03gunma.jpg" alt="群馬スタッフ求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/17/gunma/ma-155/sa-304/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/04takasaki.jpg" alt="高崎スタッフ求人" width="58" height="34" border="0" /></a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/17/gunma/ma-156/sa-385/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/05ota.jpg" alt="太田スタッフ求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/16/ibaraki/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/06ibaraki.jpg" alt="茨城スタッフ求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/16/ibaraki/ma-165/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/07mito.jpg" alt="水戸スタッフ求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/16/ibaraki/ma-164/sa-425/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/08tsuchiura.jpg" alt="土浦スタッフ求人" width="58" height="34" border="0" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bnr-large">
                        <a href="https://yarowork.jp/kanto/search/?pref%5B%5D=9" target="_blank"><img src="https://yarowork.jp/img/banner_link/yaroworkbnr_468x60.jpg" alt="栃木県の高収入求人情報 野郎WORK" /></a>
                    </div>
                </secrion>
            </div>
        </article>
    );
}

export default Enterance;